.PdfViewer {
  background-color: #080d0c;
  height: calc(100% - 45px);
  width: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 18px;
}
.PdfViewer svg {
  margin-right: 5px;
  margin-top: -2px;
}
