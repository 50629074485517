.WindowLevelPreferences {
  display: flex;
  padding: 20px 0;
  text-align: center;
}
.WindowLevelPreferences .wlColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1.5;
  margin: 0 5px;
}
.WindowLevelPreferences .wlRow {
  display: flex;
  flex-direction: row;
  margin: 5px 0;
}
.WindowLevelPreferences .preset {
  flex-grow: 0.5;
}
.WindowLevelPreferences .window,
.WindowLevelPreferences .level {
  flex-grow: 1;
}
.WindowLevelPreferences .description {
  flex-grow: 2;
}
