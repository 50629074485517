.toolbar-button {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  margin: 1px;
  margin-left: 3px;
  height: 28px;
  color: var(--text-primary-color);
  float: left;
  text-align: center;
  padding: 1px 5px 0px 5px;
  border: 1px solid transparent;
  border-radius: 5px;
  line-height: 18px;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Chrome and Opera */
}
.toolbar-button .tool-bar-button {
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toolbar-button .toolbar-button-label {
  margin-top: 3px;
  width: 32px;
  font-size: 12px;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.toolbar-button .expand-caret {
  width: 8px;
  height: 8px;
  transform: translate(2px, 2px);
}
.toolbar-button svg {
  height: 20px;
  width: 20px;
  margin: 2px;
}
.toolbar-button:hover {
  color: var(--hover-color);
}
.toolbar-button.active,
.toolbar-button:active {
  background-color: var(--hover-color-gray);
}
