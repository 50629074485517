.OHIFModal {
  font-family: inherit;
  background-color: var(--primary-background-color);
  border: 1px solid rgba(255,255,255,0.208);
  color: var(--text-secondary-color);
  border-radius: 8px;
  color: var(--text-primary-color);
  position: relative;
  -webkit-box-shadow: 0 3px 9px rgba(0,0,0,0.5);
  box-shadow: 1px #fff;
  background-clip: padding-box;
  outline: 0;
  background: linear-gradient(100deg, #13221f, #15281f);
}
@media (min-width: 450px) {
  .OHIFModal {
    width: 550px;
  }
}
@media (max-width: 420px) {
  .OHIFModal {
    width: 100%;
  }
  .OHIFModal.OHIFModal-fullscreen {
    width: 95%;
  }
}
.OHIFModal__content {
  padding: 20px;
  max-height: 90vh;
  overflow-y: auto;
}
.OHIFModal.noScroll .OHIFModal__content {
  overflow: hidden;
}
.OHIFModal:not(.visibleScrollbar) .OHIFModal__content {
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.OHIFModal:not(.visibleScrollbar) .OHIFModal__content::-webkit-scrollbar {
  display: none;
}
.OHIFModal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(105,101,101,0.502);
  padding: 20px;
  position: relative;
}
.OHIFModal__header h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-right: 24px;
  margin: 0;
}
.OHIFModal__header button {
  cursor: pointer;
  margin: -10px 0 0 0;
  padding: 0;
  background-color: transparent;
  border: none;
  color: var(--text-primary-color);
  font-size: 20px;
  font-weight: 300;
}
.OHIFModal__header button:active,
.OHIFModal__header button:focus,
.OHIFModal__header button:focus:active {
  background-image: none;
  outline: 0;
  box-shadow: none;
}
.OHIFModal .closeButton {
  margin-top: -35px;
  border: 1px solid rgba(255,255,255,0.1);
  padding: 4px;
  border-radius: 50px;
  float: right;
  width: 80px;
  background: transparent;
  color: #fff;
  margin-right: 10px;
}
.OHIFModal .closeButton:hover {
  background-color: rgba(255,255,255,0.1);
}
.OHIFModal .OHIFModal__header {
  display: flex;
  align-items: baseline;
  padding-bottom: 10px;
  padding-top: 10px;
  border-radius: 8px 8px 0 0;
  background-color: #12221f;
}
.confirmation-popup-modal {
  width: auto;
}
.confirmation-popup-modal .OHIFModal__content {
  padding: 10px;
}
