.confirmation-popup {
  width: 100%;
}
.confirmation-popup .message-div {
  font-size: 15px;
  padding: 10px 0;
}
.confirmation-popup .confirmation-footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 2px;
}
.confirmation-popup .confirmation-footer .btn {
  width: 50px;
  margin: 5px;
  color: #fff;
  text-align: -webkit-center;
}
.confirmation-popup .confirmation-footer .btn:hover {
  background-color: #397b60;
}
.confirmation-popup .confirmation-footer .btn-cancel {
  background-color: #354744;
}
