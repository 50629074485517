.HotkeysPreferences {
  display: flex;
  padding: 20px;
}
.HotkeysPreferences .hotkeyTable {
  display: flex;
  flex-direction: row;
  flex-basis: 0;
  flex-grow: 1.5;
}
.HotkeysPreferences .hotkeyTable .hotkeyColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1.5;
}
.HotkeysPreferences .hotkeyTable .hotkeyColumn .hotkeyHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.HotkeysPreferences .hotkeyTable .hotkeyColumn .hotkeyHeader .headerItemText {
  flex-basis: 0;
  flex-grow: 1.5;
  padding: 5px 15px 5px 0;
}
.HotkeysPreferences .hotkeyTable .hotkeyColumn .hotkeyRow {
  display: flex;
}
.HotkeysPreferences .hotkeyTable .hotkeyColumn .hotkeyRow .wrapperHotkeyInput {
  margin-bottom: 5px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  flex-basis: 0;
  flex-grow: 1.5;
}
.HotkeysPreferences .hotkeyTable .hotkeyColumn .hotkeyRow .hotkeyLabel {
  padding: 5px 15px 5px 0;
  text-align: right;
  flex-basis: 0;
  flex-grow: 1.5;
}
.HotkeysPreferences .hotkeyTable .hotkeyColumn .hotkeyRow .stateError .preferencesInput {
  background-color: var(--state-error);
}
