.slab-thickness {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  color: #fff;
}
.slab-thickness .container {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 5px;
  border: none;
  background-color: transparent;
}
.slab-thickness .container .toolbar-button-label {
  font-size: 11px;
  margin-left: 5px;
}
.slab-thickness .container .toolbar-slider-container {
  width: 80px;
  margin: 2px 2px 5px 2px;
  font-size: 11px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slab-thickness .container .toolbar-slider-container:hover {
  color: var(--hover-color);
}
.slab-thickness .container .toolbar-slider-container input {
  height: 20px;
  padding-top: 1px;
  color: #fff;
  filter: hue-rotate(319);
}
.slab-thickness .container .toolbar-slider-container input::-moz-range-thumb {
  height: 16px;
  margin-top: -7px;
  background-color: #009a7a;
}
.slab-thickness .container .toolbar-slider-container input::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  margin-top: -7px;
  background-color: #009a7a;
}
.slab-thickness .container .toolbar-slider-container input::-webkit-slider-runnable-track {
  background-color: #ddd;
}
.slab-thickness .container .toolbar-slider-container input::-moz-range-track {
  background-color: #ddd;
}
.slab-thickness .controller {
  width: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.slab-thickness .controller .ohif-check-container {
  margin-bottom: 5px;
  padding-left: 18px;
}
.slab-thickness .controller .ohif-check-container .ohif-check-label {
  text-transform: capitalize;
  font-size: 11px;
}
.slab-thickness .controller .ohif-check-container .ohif-check-label .ohif-checkbox {
  border-radius: 3px;
  background-color: #fff;
  width: 12px;
  height: 12px;
  top: 22%;
}
.slab-thickness .controller .ohif-check-container .ohif-check-label .ohif-checkbox.ohif-checked:after {
  background-color: #72e0e0;
  top: 17%;
  left: 17%;
}
.slab-thickness .controller .select-ohif {
  width: 56px;
  color: var(--active-color);
  border-color: var(--default-color);
  background-color: #000;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
}
.slab-thickness .controller .select-ohif:hover {
  color: var(--hover-color);
}
