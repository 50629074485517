.form-themed table {
  color: var(--text-primary-color);
}
.form-themed table th,
.form-themed table td {
  font-size: 15px;
  font-weight: normal;
}
.form-themed table th {
  line-height: 60px;
}
.form-themed table td {
  line-height: 18px;
  padding: 0;
}
.form-themed .p-r-1 {
  padding-right: 1rem !important;
}
.form-themed label {
  margin-bottom: 5px;
}
.form-themed label.wrapperLabel {
  cursor: pointer;
}
.form-themed label.wrapperLabel:not(.checkboxLabel) {
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.form-themed label.wrapperLabel:not(.checkboxLabel) .wrapperText {
  display: block;
  order: -1;
}
.form-themed .btn[disabled],
.form-themed input[type=text][disabled],
.form-themed input[type=password][disabled],
.form-themed input[type=number][disabled],
.form-themed .btn.disabled,
.form-themed input[type=text].disabled,
.form-themed input[type=password].disabled,
.form-themed input[type=number].disabled,
.form-themed .btn[disabled]:hover,
.form-themed input[type=text][disabled]:hover,
.form-themed input[type=password][disabled]:hover,
.form-themed input[type=number][disabled]:hover,
.form-themed .btn.disabled:hover,
.form-themed input[type=text].disabled:hover,
.form-themed input[type=password].disabled:hover,
.form-themed input[type=number].disabled:hover,
.form-themed .btn[disabled]:active,
.form-themed input[type=text][disabled]:active,
.form-themed input[type=password][disabled]:active,
.form-themed input[type=number][disabled]:active,
.form-themed .btn.disabled:active,
.form-themed input[type=text].disabled:active,
.form-themed input[type=password].disabled:active,
.form-themed input[type=number].disabled:active {
  background-color: var(--ui-gray-darker);
  border-color: var(--ui-gray-light);
  color: var(--text-primary-color);
}
.form-themed .btn[disabled] + .wrapperText,
.form-themed input[type=text][disabled] + .wrapperText,
.form-themed input[type=password][disabled] + .wrapperText,
.form-themed input[type=number][disabled] + .wrapperText,
.form-themed .btn.disabled + .wrapperText,
.form-themed input[type=text].disabled + .wrapperText,
.form-themed input[type=password].disabled + .wrapperText,
.form-themed input[type=number].disabled + .wrapperText,
.form-themed .btn[disabled]:hover + .wrapperText,
.form-themed input[type=text][disabled]:hover + .wrapperText,
.form-themed input[type=password][disabled]:hover + .wrapperText,
.form-themed input[type=number][disabled]:hover + .wrapperText,
.form-themed .btn.disabled:hover + .wrapperText,
.form-themed input[type=text].disabled:hover + .wrapperText,
.form-themed input[type=password].disabled:hover + .wrapperText,
.form-themed input[type=number].disabled:hover + .wrapperText,
.form-themed .btn[disabled]:active + .wrapperText,
.form-themed input[type=text][disabled]:active + .wrapperText,
.form-themed input[type=password][disabled]:active + .wrapperText,
.form-themed input[type=number][disabled]:active + .wrapperText,
.form-themed .btn.disabled:active + .wrapperText,
.form-themed input[type=text].disabled:active + .wrapperText,
.form-themed input[type=password].disabled:active + .wrapperText,
.form-themed input[type=number].disabled:active + .wrapperText {
  color: var(--text-primary-color);
  cursor: auto;
}
.form-themed input[type=text],
.form-themed input[type=password],
.form-themed input[type=number] {
  background-color: var(--ui-gray);
  border-color: var(--ui-border-color);
  color: var(--text-primary-color);
  font-weight: normal;
}
.form-themed input[type=text]:active,
.form-themed input[type=password]:active,
.form-themed input[type=number]:active,
.form-themed input[type=text]:focus,
.form-themed input[type=password]:focus,
.form-themed input[type=number]:focus {
  background-color: var(--ui-gray-dark);
  border: 2px solid var(--active-color);
  box-shadow: none;
}
.form-themed input[type=text]:active + .wrapperText,
.form-themed input[type=password]:active + .wrapperText,
.form-themed input[type=number]:active + .wrapperText,
.form-themed input[type=text]:focus + .wrapperText,
.form-themed input[type=password]:focus + .wrapperText,
.form-themed input[type=number]:focus + .wrapperText {
  color: var(--active-color);
}
.form-themed .btn-primary {
  background-color: var(--active-color);
  border-collapse: var(--ui-border-color-active);
  color: var(--ui-text-color-active);
}
.form-themed .btn-primary:hover,
.form-themed .btn-primary:active,
.form-themed .btn-primary:focus,
.form-themed .btn-primary:focus:active {
  background-color: var(--ui-active-color);
  border-collapse: var(--ui-border-color-active);
  color: var(--ui-text-color-active);
}
.form-themed .form-control {
  background-color: #fff;
  border: 0;
  font-size: 14px;
  height: 30px;
  line-height: 16px;
  padding: 8px 9px 6px;
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
}
.form-control:focus {
  border: 1px solid rgba(211,211,211,0.677);
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 8px rgba(0,0,0,0.479);
}
.form-control::-ms-expand {
  border: 0;
  background-color: transparent;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: ;
  opacity: 1;
}
.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: ;
}
input[type=range] {
  display: block;
  width: 100%;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.nowrap {
  white-space: nowrap;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
h1 {
  font-size: 36px;
}
h3,
h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
a {
  color: #337ab7;
  text-decoration: none;
}
.study-list-page {
  position: fixed;
  width: 100%;
  height: 100vh;
  height: 100svh;
}
.filterinputstyle {
  background-color: var(--input-background-color);
  color: var(--input-placeholder-color);
}
.filterinputstyle .DateRangePickerInput .DateInput input {
  color: var(--input-placeholder-color) !important;
}
.filterinputstyle .DateRangePickerInput .DateInput input::-webkit-input-placeholder {
  color: #353635 !important;
}
.filterinputstyle .DateRangePickerInput .DateRangePickerInput_arrow_svg {
  fill: #323232 !important;
}
.inputstyle {
  background-color: #111c1a;
  color: #fff;
}
.inputstyle .DateRangePickerInput .DateInput input {
  color: #fff;
}
.inputstyle .DateRangePickerInput .DateInput input::-webkit-input-placeholder {
  color: #908c8b !important;
}
.inputstyle .DateRangePickerInput .DateRangePickerInput_arrow_svg {
  fill: #787574 !important;
}
.study-list-header .addNewStudy {
  margin: 0 10px;
  color: #008000;
}
.study-list-header .addNewStudy label {
  cursor: pointer;
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
}
.study-list-header .addNewStudy input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.study-list-header .addNewStudy:hover {
  color: var(--hover-color);
}
.study-list-header .addNewStudy active {
  color: var(--active-color);
}
/* .study-list-header
  display: flex;
  justify-content: space-between;
  background-color: var(--header-backgound-color)
  height: $study-list-toolbar-height
  // margin-bottom: 2px
  padding: 0 $study-list-padding
  line-height: $study-list-toolbar-height
  margin-left:9px

  .Filter-header-buttons-div{
    background-color:#1c2324
    width:184px;
    min-width:184px
    height:100%
    display:flex;
    align-items:center
  }

  .Filter-header-buttons
    background-color: #515f5c;
    border:none;
    width:22px;
    height:20px;
    display:inline-flex;
    justify-content:center;
    align-items: center;
    margin-right:3px;

    &:hover
     cursor: pointer;
     background-color: #2c3f3a
     transform: scale(.9);
    &:active
      transform: scale(.95);

  .header 
    cursor: default;
    span
      font-size: 18px
      color: var(--text-primary-color)
      // line-height: $study-list-toolbar-height

  .actions
    display: flex;
    // align-items: center;

    .worklistButton
      color: #ffffffeb
      background-color: transparent;
      border: none;
      // border-radius: 10px;
      height: 35px;
      width: 35px;
      margin: 2px;
      cursor: pointer;

      &:hover
        background-color: rgba(255, 255, 255, 0.141)

      svg 
        font-weight: 300;
        font-size: 20px;

  .study-count
    color: var(--large-numbers-color)
    font-size: 40px
    line-height: $study-list-toolbar-height
*/
/*
.study-list-container
  background-color: #11211d
  // padding: 0 $study-list-padding
  // position: relative
  z-index: 2
  overflow-y: scroll;
*/
/*.study-table
  width: 100%
  height: calc(100% - var(--top-bar-height) - 78px)
  position: relative;
  display:flex;
*/
.showHide-arrow {
  position: fixed;
  background-color: #12221f;
  border: none;
  border-radius: 10px;
  margin-left: -10px;
  display: flex;
  align-items: center;
  z-index: 100;
  top: 50%;
}
.showHide-arrow:hover {
  background-color: #808080;
  transform: scale(0.9);
}
.showHide-arrow:active {
  transform: scale(0.95);
}
table.table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  table-layout: fixed;
  color: rgba(255,255,255,0.922);
  margin-top: 29px;
/* Striped Variant */
/* Hover Variant */
}
table.table .no-hover {
  position: absolute;
  left: 50%;
  top: 50%;
}
table.table > tbody tr.unCheckedRow {
  padding: 5px;
  background-color: #12221f;
}
table.table > tbody td {
  padding: 0px 8px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12.5px;
}
table.table > tbody tr.selectedRow {
  background-color: var(--table-hover-color);
}
table.table.table--striped > tbody tr.unCheckedRow:nth-child(even) {
  background-color: #192928;
}
table.table.table--striped > tbody tr.selectedRow:nth-child(even) {
  background-color: var(--table-hover-color);
}
table.table.table--hoverable > tbody tr.unCheckedRow:hover,
table.table.table--hoverable > tbody tr.unCheckedRow:active,
table.table.table--hoverable > tbody tr.unCheckedRow.active {
  background-color: rgba(63,130,99,0.378);
}
table.table.table--hoverable > tbody tr.unCheckedRow.no-hover:hover,
table.table.table--hoverable > tbody tr.unCheckedRow.no-hover:active,
table.table.table--hoverable > tbody tr.unCheckedRow.no-hover.active {
  background-color: var(--ui-gray-darker);
}
table.table .empty-value {
  color: var(--ui-gray-light);
}
/* .study-list-container > table.table > tr
  height: 20px

.study-list-container > table.table > thead
  ::-webkit-datetime-edit-year-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-month-field:not([aria-valuenow]),
  ::-webkit-datetime-edit-day-field:not([aria-valuenow])
    color: transparent
  top: 0;
  position: sticky;
  z-index: 2

.study-list-container > table.table > thead > tr > th
  padding:2px 1px;
  // border-bottom: 1px solid var(--ui-border-color-active);
  background-color: #182823;
  width: 100%;
  text-align: left;
  border-top: 0;

#Actions
  padding: 2px 4px;

.table-header-cell
  width: 100%;
  height: 58px
  // margin: 2px;
  padding: 2px;
  border: 1px solid #3c4b49;
  background-color: #68716f1f;

.study-list-container > table.table > thead > tr > th.StudyDate
  min-width: 230px
.study-list-container > table.table .modalities
  min-height: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

.study-list-container
  .filters
    label
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      width: 100%;
      min-width: 95px;
      margin: 0 auto;
      color: var(--table-text-primary-color);
      padding: 3px 5px;
      user-select: none;
      font-size: 13px;

      &:hover
        color: var(--active-color);

      &.active, &:active
        color: var(--active-color);
    #filter-Actions
      cursor: default

      &:hover
        color: var(--table-text-primary-color);
    i
      margin: 0 5px;

  .dateRange 
    height: 25px
    margin: 0 5px 5px 5px
    // padding: 0 5px
    cursor: pointer
    border: none !important
    font-size: 10pt
    font-weight: normal
    border-radius: 4px
    width: calc(100% - 10px); /* Just use padding? */
*
/*
  input, .selectdropdown
    height: 25px
    margin: 0 5px 5px 5px
    padding: 0 10px
    cursor: pointer
    border: none !important
    font-size: 10pt
    font-weight: normal
    border-radius: 4px
    width: calc(100% - 10px); /* Just use padding? */
::-webkit-input-placeholder {
  color: var(--input-placeholder-color);
}
:-moz-placeholder {
  color: var(--input-placeholder-color);
}
::-moz-placeholder {
  color: var(--input-placeholder-color);
}
:-ms-input-placeholder {
  color: var(--input-placeholder-color);
}
option {
  background-color: #12221f;
  color: #fff;
}
option:hover {
  color: #da1d1d !important;
  cursor: pointer;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #999;
  border: none;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #c4c0c0;
}
::-webkit-scrollbar-corner {
  background: #7b1414;
}
*
.loading-text {
  color: #86d4b1;
  text-align: center;
  font-size: 30px;
}
.notFound {
  color: #86d4b1;
  font-size: 30px;
  text-align: center;
}
.action-button {
  background-color: transparent;
  border: none;
  color: rgba(255,255,255,0.839);
  cursor: pointer;
  margin: 3px;
}
.action-button:disabled {
  color: #535653;
}
.action-button svg {
  height: 20px;
  width: 20px;
}
.rowCheckbox[type="checkbox"] {
  width: 17px;
  height: 17px;
  accent-color: #12221f;
}
.filters input[type="checkbox"] {
  margin-top: 8px;
  width: 17px;
  height: 17px;
  accent-color: #12221f;
}
.contextMenu {
  position: absolute;
  min-width: 150px;
  z-index: 10;
}
.contextMenu ::-webkit-scrollbar-track {
  background: #12221f;
}
.contextMenu ul {
  box-sizing: border-box;
  padding: 1px;
  margin: 0;
  list-style: none;
  background-color: #12221f;
  border: 1px solid #474847;
}
.contextMenu ul li {
  padding: 1px;
}
.contextMenu ul li:hover {
  color: #7ef5be;
}
.contextMenu ul .itemWithBorder {
  border-bottom: 0.5px solid rgba(123,116,116,0.605);
}
.contextMenu ul .itemWithoutBorder,
.contextMenu ul .itemWithBorder {
  margin: 5px;
  padding: 5px;
}
.contextMenu .eachitems {
  display: flex;
}
.contextMenu .eachitems .inputCheckbox[type="checkbox"] {
  width: 15px;
  height: 15px;
  accent-color: var(--table-hover-color);
}
.contextMenu .eachitems .label {
  margin-left: 5px;
}
.contextMenu .hideOrshow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255,255,255,0.382);
}
.contextMenu .hideOrshow .contextMenuButton {
  background-color: transparent;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: var(--table-hover-color);
  border-radius: 10px;
  width: 60px;
  color: #fff;
}
.contextMenu .hideOrshow .contextMenuButton:hover {
  background-color: #1f6142;
}
/* Scroll bar theming */
/*
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background: rgb(41, 47, 44)
}
::-webkit-scrollbar-thumb {
  background: rgba(109, 114, 109, 0.599)
  border: 1px solid #ffffff33
  border-radius: 5px
}
::-webkit-scrollbar-thumb:hover {
  background: #35554696
}
::-webkit-scrollbar-corner {
  background: rgb(53, 50, 50)
}
*/
.modal-header {
  position: sticky;
  background-color: #38956a;
  top: 0;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  color: var(--text-primary-color);
  padding: 5px 10px;
  border-radius: 2px;
}
.modal-header button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: var(--text-primary-color);
  width: 10px;
}
.studyinfo-body {
  display: flex;
  flex-direction: row;
  height: 88%;
  margin-top: 10px;
}
.studyinfo-body table {
  width: 95%;
  padding: 0 5px;
  border: 1px solid rgba(255,255,255,0.459);
  border-radius: 3px;
  margin: 0 5px;
}
.studyinfo-body table tr td,
.studyinfo-body table tr th {
  padding: 5px 0;
  text-align: left;
}
.comments-body {
  display: flex;
  flex-direction: row;
  height: 89%;
  margin: 0px 0px 0px 0px;
}
.comments-body .theWholeCommentsContainer {
  margin: 10px;
  width: 70%;
}
.comments-body .eachComments {
  margin: 10px 3px;
  border-bottom: 1px solid #808080;
}
.comments-body h5 {
  margin: 9px 0px;
}
.comments-body input[type = "textarea"] {
  width: 90%;
  border-radius: 5px;
  height: 30px;
  color: #fff;
  background-color: rgba(53,79,53,0.922);
  border: 1px solid #808080;
  padding-left: 2px;
  margin-right: 20px;
}
.comments-body .commentsSaveButton {
  float: right;
  width: 80px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none;
  background-color: rgba(91,138,91,0.922);
  color: #fff;
  float: left;
}
.comments-body .commentsSaveButton:hover {
  transform: scale(0.9);
}
.comments-body .commentsSaveButton:disabled {
  color: #b4a8a8;
  cursor: not-allowed;
}
.comments-body .textBoxAndSaveButton {
  display: flex;
}
.comments-body .textBoxForEnteringComments {
  margin-top: 10px;
}
.comments-body .comments {
  height: 90%;
  overflow: auto;
  margin: 0px 0px 15px 0px;
}
.comments-body .patient-details {
  width: 25%;
  padding: 0 5px;
  border-right: 1px solid rgba(255,255,255,0.459);
  margin: 0 5px;
}
.comments-body .patient-details tr {
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  text-align: left;
}
.comments-body .patient-details tr td {
  padding: 8px 0;
  padding: 8px 0;
  white-space: nowrap;
  width: 193px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.comments-body .patient-details tr th {
  background-color: #558256;
}
/*.assignto
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100%

  .assignto-body
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    max-height: 388px;

    .selectuser
      display: flex;
      align-items: center;
      margin: 5px 20px
      height: 40px;
      padding: 20px;
      width: 45%
      // border: 1px solid rgba(255, 255, 255, 0.418)
      border-radius: 5px
      background-color: rgba(31, 46, 44, 0.696)

      input[type="checkbox"]
        cursor: pointer
        height: 16px;
        width: 16px;
        accent-color: var(--table-hover-color);
      
      label
        margin: 20px;
        font-size: 15px;
        cursor: pointer;

  .assignto-footer
    position: sticky;
    bottom: 0;
    background-color: #192928;
    border-top: 1px solid rgba(255, 255, 255, 0.154);
    width: 100%
    height: 50px
    display: flex;
    align-items: center
    justify-content: flex-end;
    border-radius: 6px;

    p
      color: #ffbf00
      margin-right: 10px
      cursor: default;

    button
      padding: 5px;
      width: 55px;
      color: var(--text-primary-color)
      background-color: var(--table-hover-color)
      border: 1px solid rgba(255, 255, 255, 0.26)
      border-radius: 5px;
      margin-right: 10px
      cursor: pointer;

      &:hover
        background-color: #1f6142;

      &:disabled
        color: rgb(147, 144, 144)
        background-color: #35473e;
        cursor: not-allowed;
*/
.columnResizer {
  background-color: #182823;
  width: 3px !important;
  min-width: 3px;
  max-width: 3px !important;
  padding: 0 !important;
  border: none;
}
.columnResizer:hover {
  background-color: #5f5d5d !important;
}
.tdcolumn {
  background: transparent;
}
.tdcolumn:hover {
  background: transparent;
}
.dragOver {
  background-color: #182823;
}
/*.userConfirm
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%
  
  .userConfirm-body
    display: flex;
    flex-direction: column;
    // flex-wrap: wrap;
    padding: 10px;
    // max-height: 10px;

    h3
      color: #fbf6f6d3
      margin-bottom: 20px
      padding: 0 10px
    
    p
      font-size: 17px
      // padding: 10px
*/
*/ .userConfirm-footer {
  position: sticky;
  bottom: 0;
  background-color: #192928;
  border-top: 1px solid rgba(255,255,255,0.154);
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 6px;
}
*/ .userConfirm-footer button {
  padding: 5px;
  width: 65px;
  color: var(--text-primary-color);
  background-color: var(--table-hover-color);
  border: 1px solid rgba(255,255,255,0.26);
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}
*/ .userConfirm-footer button:hover {
  background-color: #1f6142;
}
*
.PdfViewer {
  background-color: #080d0c;
  height: calc(100% - 45px);
  color: #fff;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 18px;
}
.PdfViewer svg {
  margin-right: 5px;
  margin-top: -2px;
}
@media only screen and (max-width: 820px) {
  .study-table {
    height: calc(100% - var(--top-bar-height) - 17%);
  }
}
@media only screen and (max-width: 420px) {
  .study-table {
    height: calc(100% - var(--top-bar-height) - 20%);
  }
  .study-list-header .Filter-header-buttons-div {
    display: none;
    width: 145px;
  }
  #Reportaction-button {
    display: none;
  }
}
/*
// @media only screen and  (max-width : 820px) and (orientation: portrait)
//   .study-list-page
//     height: calc(100% - 10%);

// @media only screen and (max-height: 1024px) and (max-width : 769px)
  // .study-list-page
  //   height: 89vh;

//   .study-list-container
//     padding: 0;

//   .study-list-container > table.table > thead > tr > th
//     padding: 0 13px;

//   .study-list-container > table.table > tbody > tr > td
//     padding: 8px;

//   .study-list-container .filters label
//     padding: 8px;

// @media only screen and (max-width: 500px)
//   .hide-xs
//     display: none;
*/
.contact-details-div {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  padding: 5px;
  color: #fff;
  font-size: 16px;
}
.contact-details-div .contact-radio-main-div {
  display: flex;
  padding: 5px;
}
.contact-details-div .contact-radio-main-div .contact-radio-div {
  margin: 2px 10px;
}
.contact-details-div .contact-radio-main-div .contact-radio-div .contact-radio {
  margin-right: 3px;
  width: 15px;
  height: 15px;
  accent-color: #125d3a;
}
.contact-details-div .contact-radio-main-div .contact-radio-div .contact-radio-label {
  margin-left: 3px;
  width: 15px;
  height: 15px;
}
.contact-details-div .contact-label {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 18px;
}
.contact-details-div .contact-textbox {
  display: flex;
  padding: 5px;
  width: 300px;
  border-radius: 5px;
  background-color: #1b2120;
  border: 1px solid rgba(149,149,149,0.756);
  height: 40px;
  font-weight: 300;
  margin: 0 !important;
  color: #fff;
}
