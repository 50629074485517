.headerComponent .sub-header {
  display: flex;
  justify-content: space-between;
  background-color: var(--header-backgound-color);
  height: 30px;
  padding: 0 0%;
  line-height: 30px;
  margin: 0px;
  align-items: center;
  color: #fff;
}
.headerComponent .sub-header .header {
  font-size: 16px;
  font-weight: 400;
  margin-left: 20px;
}
.headerComponent .sub-header .actions {
  margin-right: 20px;
  display: flex;
  gap: 5px;
}
.headerComponent .sub-header .actions .headerActionButtons {
  background: #2c3f3a;
  color: #fff;
  border-radius: 5px;
  padding-inline: 10px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85px;
  cursor: pointer;
}
.headerComponent .sub-header .actions .headerActionButtons:hover {
  background: #1b1e1d;
}
