.inputBoxWholeContainer .inputboxContainer {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  border: 1px solid #616264;
  border-radius: 3px;
  margin: 15px 0;
  padding: 5px;
  color: #fff;
  width: 100%;
  height: 50px;
}
.inputBoxWholeContainer .inputboxContainer:hover {
  border-color: #fff;
}
.inputBoxWholeContainer .inputboxContainer:has(input:disabled) {
  border-color: #616264;
}
.inputBoxWholeContainer .inputboxContainer:has(input:disabled) label {
  color: #616264;
}
.inputBoxWholeContainer .inputboxContainer label {
  color: rgba(255,255,255,0.7);
/*font-family Roboto, Helvetica, Arial, sans-serif*/
  font-weight: 400;
  font-size: 16px;
  padding-inline: 5px;
  margin-left: 5px;
  background-color: #1d2422;
  width: -moz-fit-content;
  width: fit-content;
  transition: all 0.2s ease-in-out;
  margin-top: -25px;
  cursor: text;
}
.inputBoxWholeContainer .inputboxContainer input {
  background: transparent;
  border: none !important;
  outline: none !important;
  padding-inline: 10px;
  margin-top: 10px;
  color: #fff;
  font-weight: 400;
}
.inputBoxWholeContainer .inputboxContainer input:disabled {
  color: #616264;
}
.inputBoxWholeContainer .filled {
  flex-direction: column;
}
.inputBoxWholeContainer .filled label {
  cursor: default;
  font-size: 12px;
}
.inputBoxWholeContainer .active {
  border-color: #38956a !important;
  flex-direction: column;
}
.inputBoxWholeContainer .active label {
  color: #38956a;
  font-size: 12px;
  cursor: default;
}
