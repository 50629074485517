.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.btn-primary {
  color: #fff;
  background-color: rgba(91,138,91,0.922);
  border-color: #1a301a;
}
.btn-default {
  border: 1px solid rgba(255,255,255,0.1);
  padding: 5px 20px;
  border-radius: 50px;
  float: right;
  font-size: 14px;
  background: transparent;
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
}
.btn-default:hover {
  background-color: rgba(255,255,255,0.1);
}
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  align-content: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
}
button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.closeButton {
  margin-top: -35px;
  border: 1px solid rgba(255,255,255,0.1);
  padding: 4px;
  border-radius: 50px;
  float: right;
  width: 80px;
  background: transparent;
  color: #fff;
  margin-right: 10px;
}
.closeButton:hover {
  background-color: rgba(255,255,255,0.1);
}
.btn-loading {
  display: inherit;
  margin: 0;
  border: 2px solid rgba(243,243,243,0);
  border-top: 3px solid rgba(255,255,255,0.278);
  border-bottom: 3px solid rgba(255,255,255,0.278);
  width: 20px;
  height: 20px;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.flex-h {
  display: flex;
  flex-direction: row;
}
.flex-v {
  display: flex;
  flex-direction: column;
}
.flex-grow {
  flex-grow: 1;
}
.nowrap {
  white-space: nowrap;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
h1 {
  font-size: 36px;
}
h3,
h1 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  margin-top: 20px;
  margin-bottom: 10px;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
.row {
  margin-right: -15px;
  margin-left: -15px;
}
.pull-left {
  float: left !important;
}
.pull-right {
  float: right !important;
}
.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.fade.in {
  opacity: 1;
}
a {
  color: #337ab7;
  text-decoration: none;
}
.layoutChooser-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: rgba(21,21,21,0.858);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,0.486);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
  box-shadow: 0 6px 12px rgba(0,0,0,0.175);
}
.layoutChooser {
  border-radius: 8px;
  padding: 5px 0;
  position: fixed;
  top: 108px;
  left: 67vw;
  z-index: 5000;
}
.layoutChooser .selectedBefore {
  background-color: #5cc3eb;
}
.layoutChooser table {
  margin: 0 auto;
  border-spacing: 0;
  border-collapse: collapse;
}
.layoutChooser table td {
  cursor: pointer;
  border: 1px solid rgba(255,255,255,0.523);
}
.layoutChooser table td:hover,
.layoutChooser table td.hover {
  background-color: var(--header-backgound-color);
}
