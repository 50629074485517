.viewReport {
  margin: 5px 0 0 0;
  height: 98%;
  width: 100%;
  background-color: #080d0c;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 5px;
}
.viewReport .view-pdf-report {
  height: 100%;
  border-radius: 5px;
}
