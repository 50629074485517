.ResetPasswordContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.updatePasswordContainer {
  margin-top: 100px;
  margin-bottom: 80px;
  min-width: 300px;
  width: 50%;
}
.ResetPasswordContainer-mob {
  top: 0px;
  left: 10px;
  width: 100%;
}
@media (max-width: 768px) {
}
@media (max-width: 480px) {
}
