.preferencesInputErrorMessage {
  color: var(--state-error-text);
  font-size: 10px;
  text-transform: uppercase;
}
.preferencesInput {
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  background-color: var(--ui-gray);
  color: var(--text-primary-color);
  border-color: var(--ui-border-coolor);
  border: 0;
  border-radius: 2px;
  font-size: 14px;
  height: 30px;
  width: 100%;
  line-height: 16px;
  padding: 8px 9px 6px;
  text-align: center;
}
.preferencesInput:focus {
  border-color: var(--active-color);
  background-color: var(--ui-gray-dark);
  box-shadow: 0 0 0 2px var(--active-color) !important;
  outline: 0;
}
