.study-browser {
  float: left;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: var(--primary-background-color);
  padding-bottom: 20px;
}
.study-browser .scrollable-study-thumbnails {
  height: 100%;
  overflow-x: hidden;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.study-browser ::-webkit-scrollbar {
  width: 15px;
  display: block;
}
.study-browser ::-webkit-scrollbar-thumb {
  background: #11201e;
}
.study-browser ::-webkit-scrollbar-track {
  background: #192928;
}
.study-browser .noKeyImage {
  background-color: var(--secondary-background-color);
  box-shadow: inset 0 0 0 1px var(--ui-border-color);
  border: 2px solid transparent;
  border-radius: 12px;
  height: 135px;
  margin: 0 auto;
  padding: 5px;
  position: relative;
  transition: var(--sidebar-transition);
  width: 217px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #91b9cd;
  font-size: 15px;
  font-weight: 500;
}
.study-browser::-webkit-scrollbar {
  display: none;
}
.study-browser .thumbnail-container {
  margin: 0 auto;
  padding-bottom: 0.5rem;
}
.study-browser .thumbnail-container .noselect {
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer */
  -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
  -webkit-user-select: none; /* Chrome, Safari, and Opera */
  -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
.warningConfirm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.warningConfirm .warningConfirm-body {
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.warningConfirm .warningConfirm-body h3 {
  color: #eee;
  padding: 0 10px;
}
.warningConfirm .warningConfirm-body p {
  font-size: 17px;
}
.warningConfirm .warningConfirm-footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 6px;
}
.warningConfirm .warningConfirm-footer button {
  padding: 5px;
  width: 65px;
  color: var(--text-primary-color);
  background-color: #1e2622;
  border: 1px solid rgba(255,255,255,0.26);
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}
.warningConfirm .warningConfirm-footer button:hover {
  background-color: #1f6142;
}
.draggable {
  cursor: copy;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}
.StudyViewer {
  color: #fff;
  border-top: 1px solid #02341d;
  outline: 2px solid rgba(0,0,0,0);
  outline-offset: 2px;
  --tw-bg-opacity: 1;
  --tw-border-opacity: 1;
  border-top-width: 1px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}
.StudyViewer:hover {
  background-color: rgba(63,130,99,0.378);
}
.StudyViewer .EachStudy {
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.StudyViewer .EachStudy .date_series {
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.StudyViewer .EachStudy .date_series .date {
  --tw-text-opacity: 1;
  font-size: 0.8125rem;
}
.StudyViewer .EachStudy .date_series .series {
  --tw-text-opacity: 1;
  font-size: 0.8125rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.StudyViewer .EachStudy .date_series .series svg {
  width: 25px;
  height: 25px;
}
.StudyViewer .EachStudy .modality_description {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.StudyViewer .EachStudy .modality_description .modality {
  display: flex;
  font-size: 1.25rem;
  padding-right: 1.25rem;
  --tw-text-opacity: 1;
}
.StudyViewer .EachStudy .modality_description .description {
  display: flex;
  height: 2.1rem;
  line-height: 1.3;
  overflow: hidden;
  font-size: 0.8125rem;
  overflow-wrap: break-word;
  --tw-text-opacity: 1;
}
.PrimaryStudy {
  background-color: #153731;
}
.studyOptions {
  background-color: #192928;
  border-top: 1px solid #02341d;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.studyOptions .options {
  border: none;
  height: 50%;
  width: 70%;
  background-color: #080d0c;
  display: flex;
  justify-content: space-around;
  border-radius: 5px;
}
.studyOptions .options #Primary {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
}
.studyOptions .options #Saved {
  border-right: none;
}
.studyOptions .options #All {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.studyOptions .options button {
  background-color: transparent;
  color: #fff;
  width: 50%;
  border: 1px solid rgba(255,255,255,0.486);
  padding: 5px;
  font-size: 13px;
  cursor: pointer;
}
.studyOptions .options button:hover {
  background-color: #38956a;
}
.studyOptions .options .active {
  background-color: #1b4d36;
}
@media screen and (max-width: 820px) {
  .scrollable-study-thumbnails {
    overflow-y: scroll !important;
  }
  ::-webkit-scrollbar {
    display: none !important;
  }
}
