.pagination-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgba(255,255,255,0.843);
  background-color: #12211d;
  font-size: 13px;
  font-weight: normal !important;
  padding: 5px;
}
.pagination-area label {
  font-weight: normal;
}
.pagination-area select {
  margin: 5px;
  background-color: var(--primary-background-color);
  color: #fff;
  border-radius: 5px;
}
.pagination-area .pagination-details {
  display: flex;
  align-items: center;
}
.pagination-area .rows-dropdown {
  padding-right: 15px;
  padding-left: 15px;
}
.pagination-area .pagination-buttons {
  padding-right: 15px;
  padding-left: 15px;
}
.rows-per-page label.wrapperLabel {
  display: inline-table !important;
  margin: 0 4px;
}
.rows-per-page label.wrapperLabel select {
  margin: 0px 4px 0px 4px;
  width: 42px;
}
.page-buttons {
  margin: 0;
  text-align: right;
  font-weight: normal;
}
.page-buttons ul.pagination-control {
  margin: 0;
}
.page-buttons ul.pagination-control li {
  display: table-cell;
  padding: 5px 2px;
}
.page-buttons ul.pagination-control li button {
  padding: 4px 8px;
  background-color: var(--primary-background-color);
  border-color: var(--ui-gray);
  color: var(--ui-gray-darkest);
  color: #fff;
  text-decoration: none;
}
.page-buttons ul.pagination-control li button:hover:enabled {
  background-color: var(--table-hover-color);
}
.page-buttons ul.pagination-control li .active button {
  background-color: var(--ui-gray);
  border-color: #ddd;
  color: #fff;
}
@media screen and (max-width: 998px) {
  .pagination-area {
    font-size: 10px;
  }
  .pagination-area .rows-dropdown {
    padding-right: 5px;
    padding-left: 5px;
  }
  .pagination-area .pagination-buttons {
    padding-right: 5px;
    padding-left: 0px;
  }
  .pagination-area .pagination-buttons .page-buttons ul.pagination-control li {
    display: table-cell;
    padding: 5px 2px;
  }
  .pagination-area .pagination-buttons .page-buttons ul.pagination-control li button {
    padding: 4px 4px;
    font-size: 10px;
  }
  .statusPage .statusContainer {
    padding: 10px 10px;
    justify-content: space-between;
  }
  .statusPage .statusContainer .head {
    width: 100px;
  }
  .statusPage .statusContainer .report-status {
    width: 80px;
    padding: 2px 3px;
  }
}
@media screen and (max-width: 450px) {
  .pagination-area {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
}
.statusPage .statusContainer .head {
  display: none;
}
@media screen and (max-width: 730px) {
  .statusPage .statusContainer .report-status {
    width: 80px;
    height: 25px;
    padding: 2px 3px;
  }
}
