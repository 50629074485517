.AboutContent {
  height: auto;
  margin: -10px 10px;
}
.AboutContent .header {
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
}
.AboutContent .header svg {
  width: 40%;
  margin-left: -2px;
  margin-top: -40px;
}
.AboutContent .header .logo-text {
  font-size: 30px;
  color: #d2dcdb;
  margin-left: 8px;
}
.AboutContent .address {
  margin-top: 30px;
  height: 110px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}
.AboutContent .address .title {
  margin: 1px 0;
  font-size: 18px;
  font-weight: bold;
}
.AboutContent .address .sub-address {
  margin: 10px 0;
  font-size: 15px;
}
.AboutContent-buttonDiv {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.license-details {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: lighter;
}
.license-details .license-detail {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.license-details .license-label {
  font-weight: bold;
  width: 30%;
  white-space: nowrap;
}
.license-details .license-value {
  flex-grow: 1;
  word-break: break-all;
  overflow-wrap: break-word;
  padding-left: 10px;
}
@media (max-width: 768px) {
  .license-details .license-detail {
    flex-direction: column;
    align-items: flex-start;
  }
  .license-details .license-label {
    width: 100%;
    margin-bottom: 2px;
  }
  .license-details .license-value {
    width: 100%;
    padding-left: 0px;
  }
}
